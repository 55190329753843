import React, { useContext, useEffect } from "react";
import { Link } from "gatsby";
import { StateContext } from "../state/StateProvider";
import Layout from "../components/layout";
import SEO from "../components/seo";

function NotFoundPage() {
  const { isDark, setTheme } = useContext(StateContext);
  useEffect(() => {
    setTheme(false);
  }, []);
  return (
    <Layout>
      <SEO title="404: Not found" />
      <div className="flex flex-col items-center justify-center min-h-[80vh] p-3 my-8 text-center">
        <h2 className="inline-block p-2 text-2xl font-bold bg-orange">
          404: Page not found
        </h2>
        <h3 className="py-8 text-center underline c-h5 text-orange">
          <Link to="/">Go back to our homepage →</Link>
        </h3>
      </div>
    </Layout>
  );
}

export default NotFoundPage;
